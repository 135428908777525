import { assembleTablerIconUrl } from "@/lib/helpers/assembleIcon";
import { Builder, withChildren } from "@builder.io/react";
import { format } from "date-fns";
import dynamic from "next/dynamic";

Builder.set({ customInsertMenu: true });

// GI UI

Builder.register("insertMenu", {
  name: "GI UI",
  items: [
    { name: "Core:Section" },
    { name: "Container" },
    { name: "Box" },
    { name: "Columns" },
    { name: "Image" },
    { name: "Heading" },
    { name: "GIText" },
    { name: "Button" },
    { name: "BulletPoints" },
    { name: "RichText" },
    { name: "Custom Code" },
    { name: "Countdown" },
  ],
});

const Container = withChildren(
  dynamic(() => import("./components/Containers/Container.tsx"))
);
Builder.registerComponent(Container, {
  name: "Container",
  image: assembleTablerIconUrl("package"),
  inputs: [
    {
      name: "width",
      enum: ["full", "narrow"],
      defaultValue: "full",
    },
  ],
});

Builder.registerComponent(
  dynamic(() => import("./components/BuilderIO/Heading.tsx")),
  {
    name: "Heading",
    image: assembleTablerIconUrl("h-1"),
    inputs: [
      {
        name: "content",
        defaultValue: "Heading",
        type: "longText",
        helperText:
          "The content of the heading. Add '<br>' for a line break. No other HTML is valid.",
      },
      {
        name: "visualHeadingSize",
        defaultValue: "h2",
        helperText:
          "This determines the *visual size* of the heading. This can be set to whatever is needed for the design.",
        type: "string",
        enum: [
          { label: "H1", value: "h1" },
          { label: "H2", value: "h2" },
          { label: "H3", value: "h3" },
          { label: "H4", value: "h4" },
          { label: "H5", value: "h5" },
          { label: "H6", value: "h6" },
        ],
      },
      {
        name: "largeHeading",
        type: "boolean",
        defaultValue: false,
        showIf: (options) => options.get("visualHeadingSize") === "h1",
      },
      {
        name: "semanticHeadingSize",
        defaultValue: "h2",
        helperText:
          "This determines the *actual semantic size* of the heading element. This is important for SEO and accessibility.",
        type: "string",
        enum: [
          { label: "H1", value: "h1" },
          { label: "H2", value: "h2" },
          { label: "H3", value: "h3" },
          { label: "H4", value: "h4" },
          { label: "H5", value: "h5" },
          { label: "H6", value: "h6" },
        ],
      },
    ],
  }
);

Builder.registerComponent(
  dynamic(() => import("./components/BuilderIO/Text.tsx")),
  {
    name: "Text",
    image: assembleTablerIconUrl("pilcrow"),
    override: true,
    hideFromInsertMenu: true,
    inputs: [
      {
        name: "content",
        defaultValue: "Text",
        type: "longText",
      },
      {
        name: "type",
        defaultValue: "body",
        type: "string",
        enum: [
          { label: "Body Text", value: "body" },
          { label: "Small Body Text", value: "text-sm" },
          { label: "CTA", value: "text__cta" },
          { label: "Eyebrow", value: "text__eb" },
          { label: "Eyebrow 2", value: "text__eb2" },
          { label: "Eyebrow 3", value: "text__eb3" },
          { label: "Quote", value: "text__quote" },
          { label: "Tag", value: "text__tag" },
          { label: "Label", value: "text__label" },
        ],
      },
    ],
  }
);

Builder.registerComponent(
  dynamic(() => import("./components/BuilderIO/Text.tsx")),
  {
    name: "GIText",
    image: assembleTablerIconUrl("alphabet-cyrillic"),
    inputs: [
      {
        name: "content",
        defaultValue: "Text",
        type: "longText",
      },
      {
        name: "type",
        defaultValue: "body",
        type: "string",
        enum: [
          { label: "Body Text", value: "body" },
          { label: "Small Body Text", value: "text-sm" },
          { label: "CTA", value: "text__cta" },
          { label: "Eyebrow", value: "text__eb" },
          { label: "Eyebrow 2", value: "text__eb2" },
          { label: "Eyebrow 3", value: "text__eb3" },
          { label: "Quote", value: "text__quote" },
          { label: "Tag", value: "text__tag" },
          { label: "Label", value: "text__label" },
        ],
      },
    ],
  }
);

Builder.registerComponent(
  dynamic(() => import("./components/BuilderIO/Button.tsx")),
  {
    name: "Button",
    image: assembleTablerIconUrl("click"),
    override: true,
    inputs: [
      {
        name: "content",
        defaultValue: "Button",
        type: "text",
      },
      {
        name: "link",
        type: "url",
        showIf: (options) =>
          options.get("register") === false &&
          options.get("registerPage") === false,
      },
      {
        name: "opensNewTab",
        type: "url",
        type: "boolean",
        defaultValue: false,
        showIf: (options) => !!options.get("link")?.trim()
      },
      {
        name: "register",
        friendlyName: "Onboarding Button",
        defaultValue: false,
        type: "boolean",
        helperText: "If true, this will send the user to the onboarding flow.",
        showIf: (options) => options.get("registerPage") === false,
      },
      {
        name: "registerPage",
        friendlyName: "Registration Page",
        type: "boolean",
        defaultValue: false,
        helperText:
          "If true, this will send users directly to the registration page, and return users to this page after checkout is completed.",
        showIf: (options) => options.get("register") === false,
      },
      {
        name: "memberButtonData",
        helperText:
          "This alternate button data will be shown to members when they are logged in.",
        type: "object",
        showIf: (options) =>
          options.get("register") === true ||
          options.get("registerPage") === true,
        subFields: [
          {
            name: "userContent",
            friendlyName: "Member Button Text",
            helperText: "Text for the button when a subscriber is logged in.",
          },
          {
            name: "userLink",
            friendlyName: "Member Button URL",
            type: "url",
            helperText: "URL for the button when a subscriber is logged in.",
          },
        ],
        defaultValue: {
          userContent: "View Library",
          userLink: "/library",
        },
      },
      {
        name: "variants",
        friendlyName: "Variant",
        defaultValue: "primary",
        enum: ["base", "primary", "secondary", "hollow", "text", "dark"],
      },
      {
        name: "size",
        defaultValue: "large",
        enum: ["small", "large"],
        showIf: (options) => options.get("register") === false,
      },
      {
        name: "fullWidth",
        defaultValue: true,
        type: "boolean",
      },
      {
        name: "dataId",
        friendlyName: "Location",
        type: "text",
        helperText:
          "Used for analytics. Will be tracked in a `CTA Clicked` event.",
      },
      {
        name: "prefixIcon",
        enum: [
          "None",
          "arrowLeft",
          "arrowRight",
          "caretDown",
          "caretLeft",
          "caretUp",
          "complete",
          "download",
          "externalLink",
          "link",
          "save",
          "share",
          "tick",
          "time",
          "gift",
          "drawerCaret",
        ],
      },
      {
        name: "suffixIcon",
        enum: [
          "None",
          "arrowLeft",
          "arrowRight",
          "caretDown",
          "caretLeft",
          "caretUp",
          "complete",
          "download",
          "externalLink",
          "link",
          "save",
          "share",
          "tick",
          "time",
          "gift",
          "drawerCaret",
        ],
      },
    ],
  }
);

Builder.registerComponent(
  dynamic(() => import("./components/BuilderIO/BulletPoints.tsx")),
  {
    name: "BulletPoints",
    image: assembleTablerIconUrl("list"),
    inputs: [
      {
        name: "columns",
        friendlyName: "Desktop Columns",
        enum: [1, 2, 3, 4],
        defaultValue: 1,
      },
      {
        name: "columnGap",
        helperText: "Gap between columns in pixels.",
        type: "number",
        defaultValue: 20,
      },
      {
        name: "rowGap",
        helperText: "Gap between bullet points in pixels.",
        type: "number",
        defaultValue: 20,
      },
      {
        name: "fontSize",
        type: "number",
        defaultValue: 14,
        min: 0,
        max: 100,
        helperText: "Enter the value in pixels.",
      },
      {
        name: "listItems",
        type: "list",
        defaultValue: [{ text: "Default bullet point", icon: "tickRounded" }],
        subFields: [
          {
            name: "text",
            type: "string",
            defaultValue: "Add Text",
          },
          {
            name: "icon",
            enum: ["None", "tickRounded", "starBullet", "Custom"],
            defaultValue: "None",
          },
          {
            name: "customIcon",
            friendlyName: "Custom Icon [Custom only]",
            type: "file",
            allowedFileTypes: ["svg"],
            helperText:
              "Upload an SVG file. Icon must be set to 'Custom' above.",
          },
          {
            name: "iconWidth",
            friendlyName: "Icon Width [Custom only]",
            type: "number",
            defaultValue: 24,
            min: 0,
            max: 100,
            helperText:
              "Enter the value in pixels. Icon must be set to 'Custom' above.",
          },
        ],
      },
    ],
  }
);

Builder.registerComponent(
  dynamic(() => import("./components/BuilderIO/RichText.tsx")),
  {
    name: "RichText",
    image: assembleTablerIconUrl("file-text"),
    inputs: [
      {
        name: "content",
        type: "richText",
        requiredPermissions: ["admin"],
      },
    ],
  }
);

// GI Sections

Builder.register("insertMenu", {
  name: "GI Sections",
  items: [
    { name: "faqSection" },
    { name: "tolstoyWidget" },
    { name: "Carousel" },
    { name: "Card" },
    { name: "priceSelect" },
    { name: "mediaAccordion" },
    { name: "LogoMarquee" },
    { name: "SeasonalCallout" },
    { name: "CopyLinkButton" },
    { name: "Tabs" },
    { name: "CountdownBannerSection" },
  ],
});

Builder.registerComponent(
  dynamic(() => import("./components/BuilderIO/FAQ/index.tsx")),
  {
    name: "faqSection",
    image: assembleTablerIconUrl("list-details"),
    inputs: [
      {
        name: "faqs",
        friendlyName: "FAQ items",
        type: "list",
        defaultValue: [
          {
            title: "Question",
            content: "Answer",
          },
        ],
        subFields: [
          {
            name: "title",
            type: "string",
            defaultValue: "Question",
          },
          {
            name: "content",
            type: "richText",
            defaultValue: "Answer",
          },
          {
            name: "open",
            type: "boolean",
            defaultValue: false,
            helperText:
              "Toggle to open this FAQ by default, or to preview the open state in the Visual Editor.",
          },
        ],
      },
      {
        name: "textSizing",
        type: "string",
        enum: [
          { label: "Small", value: "small" },
          { label: "Large", value: "large" },
        ],
        defaultValue: "large",
      },
    ],
  }
);

Builder.registerComponent(
  dynamic(() => import("./components/TolstoyEmbed/index.js")),
  {
    name: "tolstoyWidget",
    image: assembleTablerIconUrl("message-circle"),
    inputs: [
      {
        name: "widgetId",
        friendlyName: "Widget ID",
        type: "string",
      },
      {
        name: "delayInterval",
        type: "number",
        defaultValue: 2,
      },
      {
        name: "alwaysShow",
        friendlyName: "Always show widget",
        helperText: "Show widget even if user has already closed it.",
        type: "boolean",
        defaultValue: false,
      },
      {
        name: "disallowedUrls",
        friendlyName: "Disallowed URLs",
        helperText:
          "Enter URLs, or partial URLs, where Tolstoy should be hidden. Multiple URLs can be hidden this way. Separate each entry with a comma. (e.g. 'teacher_appreciation, another_url'. In this example, any URL containing EITHER 'teacher_appreciation' OR 'another_url' will not render the Tolstoy widget.",
        type: "string",
      },
    ],
  }
);

const CarouselWithChildren = withChildren(
  dynamic(() => import("./components/BuilderIO/Carousel/index.tsx"))
);
Builder.registerComponent(CarouselWithChildren, {
  name: "Carousel",
  image: assembleTablerIconUrl("carousel-horizontal"),
  inputs: [
    {
      type: "boolean",
      defaultValue: true,
      name: "disableOnInteraction",
      helperText:
        "Stop auto play of slides when user interacts with carousel (ie swipes on there own or plays a video with in the carousel)",
    },
    {
      name: "slidesPerView",
      helperText: "Visible slides for mobile viewport (640px and smaller)",
      type: "number",
      defaultValue: 2,
    },
    {
      name: "spaceBetween",
      type: "number",
      defaultValue: 10,
      helperText: "< 640px",
    },
    {
      name: "navigation",
      type: "boolean",
      defaultValue: true,
    },
    {
      name: "centeredSlides",
      type: "boolean",
      defaultValue: false,
      helperText: "This will also enable looping",
      showIf: (options) => [false, undefined].includes(options.get("loop")),
    },
    {
      name: "loop",
      type: "boolean",
      defaultValue: false,
      helperText:
        "Enable this to go back to first slide after user moves past last slide",
      showIf: (options) => options.get("centeredSlides") === false,
    },
    {
      name: "matchHeight",
      friendlyName: "Match Slide Height",
      helperText:
        "Match the height of all slides to the height of the tallest slide.",
      type: "boolean",
      defaultValue: true,
    },
    {
      name: "fadeColor",
      type: "string",
      defaultValue: "none",
      enum: ["white", "gray", "none"],
    },
    {
      name: "pagination",
      type: "boolean",
      defaultValue: false,
    },
    {
      name: "autoplay",
      type: "boolean",
      helperText: "Automatically scroll cards?",
      defaultValue: "false",
    },
    {
      name: "autoplayDelay",
      type: "integer",
      helperText: "Seconds between card transitions",
      defaultValue: 2,
      showIf: (options) => options.get("autoplay") === true,
    },
    {
      name: "mediumBreakpoint",
      type: "object",
      helperText:
        "Visible slides for desktop viewport (between 640px and 991px)",
      subFields: [
        {
          name: "slidesPerView",
          type: "number",
        },
        {
          name: "spaceBetween",
          type: "number",
        },
      ],
      defaultValue: {
        slidesPerView: 2.2,
        spaceBetween: 20,
      },
    },
    {
      name: "largeBreakpoint",
      type: "object",
      helperText: "Visible slides for desktop viewport (992px and larger)",
      subFields: [
        {
          name: "slidesPerView",
          type: "number",
        },
        {
          name: "spaceBetween",
          type: "number",
        },
      ],
      defaultValue: {
        slidesPerView: 4.2,
        spaceBetween: 24,
      },
    },
  ],
  defaultChildren: [
    {
      "@type": "@builder.io/sdk:Element",
      component: {
        name: "Card",
        options: {
          cardType: "editorial",
          heading: "Card Title",
          subHeading: "Card Subtitle",
          text: "Lorem ipsum dolor semet",
          cardHeight: 400,
          bgColor: "#f3f3f3f3",
        },
      },
    },
    {
      "@type": "@builder.io/sdk:Element",
      component: {
        name: "Card",
        options: {
          cardType: "editorial",
          heading: "Card Title",
          subHeading: "Card Subtitle",
          text: "Lorem ipsum dolor semet",
          cardHeight: 400,
          bgColor: "#f3f3f3f3",
        },
      },
    },
    {
      "@type": "@builder.io/sdk:Element",
      component: {
        name: "Card",
        options: {
          cardType: "editorial",
          heading: "Card Title",
          subHeading: "Card Subtitle",
          text: "Lorem ipsum dolor semet",
          cardHeight: 400,
          bgColor: "#f3f3f3f3",
        },
      },
    },
    {
      "@type": "@builder.io/sdk:Element",
      component: {
        name: "Card",
        options: {
          cardType: "editorial",
          heading: "Card Title",
          subHeading: "Card Subtitle",
          text: "Lorem ipsum dolor semet",
          cardHeight: 400,
          bgColor: "#f3f3f3f3",
        },
      },
    },
    {
      "@type": "@builder.io/sdk:Element",
      component: {
        name: "Card",
        options: {
          cardType: "editorial",
          heading: "Card Title",
          subHeading: "Card Subtitle",
          text: "Lorem ipsum dolor semet",
          cardHeight: 400,
          bgColor: "#f3f3f3f3",
        },
      },
    },
    {
      "@type": "@builder.io/sdk:Element",
      component: {
        name: "Card",
        options: {
          cardType: "editorial",
          heading: "Card Title",
          subHeading: "Card Subtitle",
          text: "Lorem ipsum dolor semet",
          cardHeight: 400,
          bgColor: "#f3f3f3f3",
        },
      },
    },
  ],
});

Builder.registerComponent(
  dynamic(() => import("./components/BuilderIO/Cards/index.tsx")),
  {
    name: "Card",
    image: assembleTablerIconUrl("id"),
    noWrap: true,
    inputs: [
      {
        name: "cardType",
        friendlyName: "Card Type",
        type: "string",
        defaultValue: "editorial",
        enum: [
          { label: "Editorial Card", value: "editorial" },
          { label: "Testimonial Card", value: "testimonial" },
          { label: "Employee Card", value: "employee" },
          { label: "Quote Card", value: "quote" },
        ],
      },
      {
        name: "rounded",
        friendlyName: "Rounded Corners",
        type: "boolean",
        defaultValue: false,
        showIf: (options) => options.get("cardType") !== "quote",
      },
      {
        name: "heading",
        type: "text",
        showIf: (options) => options.get("cardType") !== "quote",
      },
      {
        name: "headingSize",
        type: "string",
        enum: [
          { label: "H1", value: "heading__2xl" },
          { label: "H2", value: "heading__xl" },
          { label: "H3", value: "heading__lg" },
          { label: "H4", value: "heading__md" },
          { label: "H5", value: "heading__sm" },
          { label: "H6", value: "heading__xs" },
        ],
        showIf: (options) =>
          options.get("cardType") === "testimonial" &&
          options.get("heading") !== "",
        helperText:
          "This will set the *visual size* of the heading. This can be set to whatever is needed for the design.",
      },
      {
        name: "textSize",
        type: "string",
        enum: [
          { label: "Body", value: "body" },
          { label: "Display", value: "display" },
        ],
        showIf: (options) =>
          options.get("cardType") === "testimonial" &&
          options.get("heading") == "",
      },
      {
        name: "subHeading",
        type: "text",
        showIf: (options) => options.get("cardType") === "editorial",
      },
      {
        name: "text",
        type: "longText",
      },
      {
        name: "lineHeight",
        type: "number",
        defaultValue: 26,
        helperText:
          "This will set the line height for the body text. Enter the value in pixels.",
        showIf: (options) => options.get("cardType") === "testimonial",
      },
      {
        name: "citationPosition",
        type: "string",
        enum: [
          { label: "aboveText", value: "above" },
          { label: "belowText", value: "below" },
        ],
        defaultValue: "below",
        showIf: (options) => options.get("cardType") === "testimonial",
      },
      {
        name: "citation",
        type: "text",
        showIf: (options) => {
          SHOW_CARD_TYPES = ["testimonial", "employee"];
          return SHOW_CARD_TYPES.includes(options.get("cardType"));
        },
      },
      {
        name: "citeDate",
        friendlyName: "Citation date",
        type: "date",
        defaultValue: "06/09/2023",
        showIf: (options) => options.get("cardType") === "testimonial",
      },
      {
        name: "verifiedUser",
        type: "boolean",
        defaultValue: false,
        showIf: (options) => options.get("cardType") === "testimonial",
      },
      {
        name: "ratingColor",
        friendlyName: "Rating/Star Color",
        type: "color",
        defaultValue: "rgb(249,204,48)",
        showIf: (options) => options.get("cardType") === "testimonial",
      },
      {
        name: "additionalQuestions",
        type: "list",
        defaultValue: [
          {
            question: "How old are your children?",
            answer: "2-5 years old",
          },
        ],
        subFields: [
          {
            name: "question",
            type: "longText",
          },
          {
            name: "answer",
            type: "longText",
          },
        ],
        showIf: (options) => options.get("cardType") === "testimonial",
      },
      {
        name: "textColor",
        type: "string",
        enum: [
          { label: "White", value: "text-white" },
          { label: "Black", value: "text-black" },
          { label: "Green", value: "text-dark-green" },
          { label: "Blue", value: "text-blue" },
          { label: "Red", value: "text-red" },
        ],
      },
      {
        name: "textJustify",
        friendlyName: "Justify Text",
        type: "string",
        enum: ["left", "center", "right"],
        showIf: (options) => options.get("cardType") !== "employee",
      },
      {
        name: "contentPosition",
        type: "string",
        enum: [
          { label: "top", value: "items-start" },
          { label: "center", value: "items-center" },
          { label: "bottom", value: "items-end" },
        ],
        showIf: (options) => options.get("cardType") === "editorial",
      },
      {
        name: "cardHeight",
        type: "number",
        helperText:
          "Height in pixels. This will set the height of this individual card. Note that the carousel will format all cards to be the height of the tallest card.",
        showIf: (options) => options.get("cardType") === "editorial",
      },
      {
        name: "image",
        type: "file",
        allowedFileTypes: ["jpeg", "png", "svg"],
        showIf: (options) => {
          SHOW_CARD_TYPES = ["editorial", "employee", "blog", "quote"];
          return SHOW_CARD_TYPES.includes(options.get("cardType"));
        },
      },
      {
        name: "imageAlt",
        friendlyName: "image alt text",
        type: "string",
        defaultValue: "good inside image",
        showIf: (options) => {
          return (
            options.get("image") !== undefined && options.get("image") !== ""
          );
        },
      },
      {
        name: "imageWidth",
        friendlyName: "Image Width",
        type: "number",
        showIf: (options) =>
          ![undefined, ""].includes(options.get("image")) &&
          options.get("cardType") === "quote",
      },
      {
        name: "imageHeight",
        friendlyName: "Image Height",
        type: "number",
        showIf: (options) =>
          ![undefined, ""].includes(options.get("image")) &&
          options.get("cardType") === "quote",
      },
      {
        name: "imagePosition",
        friendlyName: "Image Position",
        type: "string",
        defaultValue: "background",
        enum: [
          { label: "Background", value: "background" },
          { label: "Above Text", value: "above" },
        ],
        showIf: (options) =>
          options.get("image") !== undefined &&
          options.get("image") !== "" &&
          options.get("cardType") === "editorial",
      },
      {
        name: "imageOverlay",
        friendlyName: "Image Overlay",
        type: "color",
        showIf: (options) => {
          return (
            options.get("image") !== undefined &&
            options.get("image") !== "" &&
            options.get("cardType") === "editorial"
          );
        },
      },
      {
        name: "overlayGradient",
        friendlyName: "Overlay Gradient",
        helperText:
          "This will fade the overlay color above in the chosen direction.",
        type: "string",
        defaultValue: "none",
        enum: [
          { label: "None", value: "none" },
          { label: "Fade from Bottom", value: "bottom" },
          { label: "Fade from Top", value: "top" },
          { label: "Fade from Left", value: "left" },
          { label: "Fade from Right", value: "right" },
          { label: "Fade from Bottom Left", value: "bottom left" },
          { label: "Fade from Bottom Right", value: "bottom right" },
          { label: "Fade from Top Left", value: "top left" },
          { label: "Fade from Top Right", value: "top right" },
        ],
        showIf: (options) => {
          return (
            options.get("image") !== undefined &&
            options.get("image") !== "" &&
            options.get("imageOverlay") !== undefined &&
            options.get("imageOverlay") !== ""
          );
        },
      },
      {
        name: "bgColor",
        friendlyName: "Background Color",
        type: "color",
        defaultValue: "#fafafa",
        showIf: (options) => {
          return (
            options.get("imagePosition") === "above" ||
            options.get("image") === "" ||
            options.get("image") === undefined
          );
        },
      },
      {
        name: "quoteColor",
        friendlyName: "Quote Color",
        type: "color",
        defaultValue: "#6DB0F2",
        showIf: (options) => options.get("cardType") === "quote",
      },
      {
        name: "cardLink",
        helperText: "Link the entire card to a URL.",
        type: "url",
        showIf: (options) => options.get("addBtn") === false,
      },
      {
        name: "addBtn",
        friendlyName: "Add Button",
        type: "boolean",
        defaultValue: false,
        showIf: (options) =>
          options.get("cardType") === "editorial" &&
          (options.get("cardLink") === undefined ||
            options.get("cardLink") === ""),
      },
      {
        name: "btnText",
        friendlyName: "Button text",
        type: "text",
        defaultValue: "GI Button",
        showIf: (options) => options.get("addBtn") === true,
      },
      {
        name: "btnVariants",
        friendlyName: "Button Variant",
        defaultValue: "primary",
        enum: ["base", "primary", "secondary", "hollow", "text", "dark"],
        showIf: (options) => options.get("addBtn") === true,
      },
      {
        name: "btnLink",
        friendlyName: "Button Link",
        type: "url",
        showIf: (options) => options.get("addBtn") === true,
      },
      {
        name: "bntToggleRegistration",
        friendlyName: "Toggle Registration",
        type: "boolean",
        defaultValue: false,
        showIf: (options) => options.get("addBtn") === true,
      },
    ],
  }
);

Builder.registerComponent(
  dynamic(() => import("./components/BuilderIO/PriceSelect/index.tsx")),
  {
    name: "priceSelect",
    image: assembleTablerIconUrl("tag"),
    inputs: [
      {
        name: "title",
        type: "string",
        defaultValue: "Choose Your Membership",
      },
      {
        name: "prices",
        type: "list",
        defaultValue: [
          {
            priceName: "Starter Pack",
            description: "3 months of digital Membership.",
            description_2: "Billed quarterly. Cancel anytime.",
            price: "$84",
            link: "/membership/checkout",
            selected: false,
          },
          {
            priceName: "Insider",
            description: "1 year of digital Membership.",
            description_2: "Billed annually. Cancel anytime.",
            price: "$276",
            link: "/membership/checkout",
            selected: false,
          },
        ],
        subFields: [
          {
            name: "priceName",
            type: "string",
          },
          {
            name: "description",
            type: "longText",
          },
          {
            name: "description_2",
            friendlyName: "Description Line 2",
            type: "longText",
          },
          {
            name: "originalPrice",
            type: "string",
            helperText:
              "e.g. $28. This price will be shown with a strikethrough.",
          },
          {
            name: "price",
            type: "string",
          },
          {
            name: "priceUnit",
            type: "string",
            helperText: "e.g. /month, /year, etc.",
          },
          {
            name: "link",
            type: "url",
          },
          {
            name: "selected",
            type: "boolean",
            defaultValue: false,
          },
          {
            name: "divider",
            type: "boolean",
            helperText: "Add a divider above this price option.",
            defaultValue: false,
          },
          {
            name: "badgeText",
            type: "string",
            helperText: "Add a badge to this price option.",
          },
        ],
      },
      {
        name: "buttonText",
        type: "string",
        defaultValue: "Choose Plan",
        helperText:
          "Note: button link is set by the link of the selected price option.",
      },
      {
        name: "disableRadioButtons",
        type: "boolean",
        defaultValue: true,
      },
    ],
  }
);

Builder.registerComponent(
  dynamic(() => import("./components/BuilderIO/MediaAccordionBuilder.js")),
  {
    name: "mediaAccordion",
    image: assembleTablerIconUrl("elevator"),
    inputs: [
      {
        name: "slides",
        type: "list",
        friendlyName: "Accordion item",
        defaultValue: [
          {
            title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            content:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sit amet rutrum dolor. Vestibulum bibendum nisi eu elit fringilla consequat. Sed sit amet sapien viverra, finibus mauris a, cursus ipsum.",
          },
        ],
        subFields: [
          {
            name: "title",
            type: "string",
          },
          {
            name: "content",
            type: "string",
          },
          {
            name: "image",
            type: "file",
            allowedFileTypes: ["jpeg", "png", "svg"],
          },
          {
            name: "imageAlt",
            type: "text",
            defaultValue: "Good Inside Image",
          },
          {
            name: "video",
            type: "file",
            allowedFileTypes: ["mp4", "mov", "webm", "wmv"],
          },
        ],
      },
    ],
  }
);

Builder.registerComponent(
  dynamic(() => import("./components/BuilderIO/LogoMarqueeBuilder.js")),
  {
    name: "LogoMarquee",
    image: assembleTablerIconUrl("dots"),
    inputs: [
      {
        name: "logos",
        type: "list",
        subFields: [
          {
            name: "image",
            type: "file",
            allowedFileTypes: ["jpeg", "png", "svg"],
          },
          {
            name: "imageAlt",
            type: "text",
            helperText:
              "Alt text for logo. Important for SEO and accessibiilty.",
          },
          {
            name: "width",
            type: "number",
            helperText: "Required. Enter the value in pixels.",
            required: true,
            min: 0,
          },
          {
            name: "height",
            type: "number",
            helperText: "Required. Enter the value in pixels.",
            requireed: true,
            min: 0,
          },
        ],
      },
      {
        name: "paddingTop",
        type: "number",
        defaultValue: 28,
        helperText: "Enter the value in pixels.",
        min: 0,
      },
      {
        name: "paddingBottom",
        type: "number",
        defaultValue: 28,
        helperText: "Enter the value in pixels.",
        min: 0,
      },
    ],
  }
);

Builder.registerComponent(
  dynamic(() => import("@/components/BuilderIO/SeasonalCallout.js")),
  {
    name: "SeasonalCallout",
    image: assembleTablerIconUrl("calendar-clock"),
    inputs: [
      {
        name: "showLatestCallout",
        type: "boolean",
        defaultValue: true,
        helperText:
          "Toggle to show the latest Seasonal Callout. Priority can be set under Structured Data Models > Seasonal Callouts.",
      },
      {
        name: "callout",
        type: "model",
        type: "reference",
        model: "seasonal-callouts",
        showIf: (options) => options.get("showLatestCallout") === false,
      },
    ],
  }
);

Builder.registerComponent(
  dynamic(() => import("@/components/BuilderIO/CopyLinkButton.tsx")),
  {
    name: "CopyLinkButton",
    image: assembleTablerIconUrl("click"),
    inputs: [
      {
        name: "buttonText",
        type: "string",
        defaultValue: "COPY LINK",
      },
      {
        name: "size",
        type: "string",
        defaultValue: "wide",
        enum: ["large", "small", "wide"],
      },
      {
        name: "url",
        type: "string",
      },
    ],
  }
);

Builder.registerComponent(
  dynamic(() => import("@/components/BuilderIO/Tabs.js")),
  {
    name: "Tabs",
    image: assembleTablerIconUrl("click"),
    inputs: [
      {
        name: "tabs",
        type: "list",
        subFields: [
          {
            name: "label",
            type: "text",
            defaultValue: "New tab",
          },
          {
            name: "content",
            type: "uiBlocks",
            required: true,
            defaultValue: "default content",
          },
        ],
        defaultValue: [
          {
            label: "Tab Label",
            content: [],
          },
        ],
      },
      {
        name: "alignment",
        type: "string",
        defaultValue: "justify-between",
        enum: [
          { label: "Centered", value: "justify-between" },
          { label: "Left-Justified", value: "justify-start" },
        ],
      },
      {
        name: "padding",
        type: "string",
        defaultValue: "px-0",
        helperText: "How much horizontal space between tabs?",
        enum: [
          { label: "0px", value: "gap-0" },
          { label: "2px", value: "gap-0.5" },
          { label: "4px", value: "gap-1" },
          { label: "6px", value: "gap-1.5" },
          { label: "8px", value: "gap-2" },
          { label: "10px", value: "gap-2.5" },
          { label: "12px", value: "gap-3" },
          { label: "14px", value: "gap-3.5" },
          { label: "16px", value: "gap-4" },
        ],
      },
    ],
  }
);

Builder.registerComponent(
  dynamic(() =>
    import("./components/BuilderIO/CountdownBannerSection/index.tsx")
  ),
  {
    name: "CountdownBannerSection",
    image: assembleTablerIconUrl("hourglass"),
    inputs: [
      {
        type: "date",
        name: "eventDate",
        friendlyName: "Event Date",
        defaultValue: format(Date.now(), "MM/dd/yyyy"),
      },
      {
        type: "richText",
        name: "titleText",
        friendlyName: "Title Text",
        defaultValue: "Event Title Here",
        helperText:
          "You can use rich text to define your own style for the text",
      },
      {
        type: "richText",
        name: "subtitleText",
        friendlyName: "Subtitle Text",
        defaultValue: "Subtitle here",
        helperText:
          "You can use rich text to define your own style for the text",
      },
      {
        type: "boolean",
        name: "includeSeconds",
        helperText:
          "Set to true if you want to include seconds in the countdown timer",
        defaultValue: true,
      },
      {
        type: "color",
        defaultValue: "#C2E1FF",
        name: "backgroundColor",
        friendlyName: "Banner Color",
      },
      {
        type: "url",
        name: "memberRsvpLink",
        friendlyName: "Member RSVP Link",
        helperText: "Link to follow for members to access RSVP for the event",
      },
      {
        type: "boolean",
        name: "showMemberRsvpLink",
        helperText:
          "Turn this on if you want to skip checking for user's active subscription",
        defaultValue: false,
      },
    ],
  }
);

Builder.registerComponent(
  dynamic(() => import("./components/Countdown/index.tsx")),
  {
    name: "Countdown",
    image: assembleTablerIconUrl("hourglass"),
    inputs: [
      {
        type: "date",
        name: "eventDate",
        friendlyName: "Event Date",
        defaultValue: format(Date.now(), "MM/dd/yyyy"),
      },
      {
        type: "boolean",
        name: "includeSeconds",
        helperText:
          "Set to true if you want to include seconds in the countdown timer",
        defaultValue: false,
      },
    ],
  }
);
