import Icon from "@/components/Icon";
import { useUI } from "@/context/UIContext";
import { useUser } from "@/context/UserContext";
import { track } from "@/lib/helpers/analytics";
import isValidRelativeURL from "@/lib/helpers/validRelativeUrl";
import { ROUTES } from "@/lib/routes";
import { ButtonProps, btnVariants, btnSizes } from "@/types/button";
import cn from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { MouseEventHandler, useState, useEffect } from "react";

type MemberButtonData = {
  userContent?: string;
  userLink?: string;
};

type OnboardingButtonProps = ButtonProps & {
  registerPage?: boolean;
  register?: boolean;
  memberButtonData?: MemberButtonData;
};

const OnboardingButton: React.FC<OnboardingButtonProps> = ({
  variant = "primary",
  children,
  className,
  size = "large",
  fullWidth,
  dataId,
  suffixIcon,
  prefixIcon,
  registerPage,
  memberButtonData,
}) => {
  const router = useRouter();
  const [destination, setDestination] = useState("");
  const [buttonContent, setButtonContent] = useState(children);
  const { user } = useUser();
  const { dispatchModal } = useUI();

  useEffect(() => {
    if (!user) {
      setDestination(
        registerPage ? ROUTES.MEMBERSHIP_CHECKOUT : ROUTES.ONBOARDING
      );
      setButtonContent(children);
    } else {
      if (user.subscription_active) {
        const customMemberLink = isValidRelativeURL(
          memberButtonData?.userLink ?? ""
        );
        const memberDestination = customMemberLink
          ? memberButtonData?.userLink ?? ROUTES.LIBRARY
          : ROUTES.LIBRARY;
        setDestination(memberDestination);
        setButtonContent(memberButtonData?.userContent ?? "Library");
      } else {
        setDestination(ROUTES.MEMBERSHIP_CHECKOUT);
        setButtonContent(children);
      }
    }
  }, [user, registerPage, memberButtonData, children]);

  const renderIcon = (icon = "") => (
    <div
      className={cn({
        ["btn-icon ml-2.5"]: !!suffixIcon && suffixIcon !== "None",
        ["btn-icon mr-2.5"]: !!prefixIcon && prefixIcon !== "None",
      })}
    >
      <Icon name={icon} />
    </div>
  );

  const handleRouting: MouseEventHandler<HTMLAnchorElement> = async (e) => {
    e.preventDefault();

    localStorage.setItem("nextUrl", router.asPath);

    const target = e.target as HTMLAnchorElement;

    track("CTA Clicked", {
      location: dataId || "",
      text: target.textContent,
      destination: destination,
    });

    router.push(destination);

    dispatchModal({
      type: "SET_OPEN",
      value: false,
    });
    dispatchModal({ type: "CLEAR" });
  };

  return (
    <Link
      href={destination}
      onClick={handleRouting}
      className={cn(
        "mb-6 md:mb-0",
        btnVariants[variant],
        btnSizes[size],
        { ["btn-full-width"]: fullWidth },
        "md-max:outline-none",
        className
      )}
    >
      {prefixIcon && prefixIcon !== "None" && renderIcon(prefixIcon)}
      {buttonContent || "Join now"}
      {suffixIcon && suffixIcon !== "None" && renderIcon(suffixIcon)}
    </Link>
  );
};

export default OnboardingButton;
