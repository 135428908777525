import { useUser } from "@/context/UserContext";
import api from "@/lib/api";
import {
  GI_JWT_TOKEN_COOKIE,
  RETURNING_SUBSCRIBER_KEY,
  USER_AUTH_URL,
} from "@/lib/constants";
import { validateStripeStatus } from "@/lib/helpers/validateStripeStatus";
import { ROUTES } from "@/lib/routes";
import { useGrowthBook } from "@growthbook/growthbook-react";
import * as Sentry from "@sentry/nextjs";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import React, { createContext, useContext, useEffect, useState } from "react";
import aa from "search-insights";

const AuthContext = createContext();

function useProvideAuth() {
  const router = useRouter();
  const growthbook = useGrowthBook();

  /**
   * User is null by default, if we receive a user the data is updated,
   * if the request returns anything but a successful payload set user to false
   * a user !== null check ensures that the user request has returned a value
   * */
  const { user, setUser, setAuthVerified } = useUser(null);

  // An ephemeral, in-memory JWT for authenticated/gated API requests
  const [token, setToken] = useState(null);
  const [chatbotToken, setChatbotToken] = useState(null);

  // Set local storage flag if user is a returning member
  useEffect(() => {
    if (!!user && user.subscription_active) {
      window.localStorage.setItem(RETURNING_SUBSCRIBER_KEY, true);
      window.userLoggedIn = true;
      window.isAuthVerified = true;

      // Search Insights
      aa("init", {
        appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
        apiKey: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY,
      });

      aa("setUserToken", `user-${user.id}`);
    } else if (!!user) {
      window.userLoggedIn = true;
    } else {
      window.localStorage.removeItem(RETURNING_SUBSCRIBER_KEY);
      window.userLoggedIn = false;
      window.isAuthVerified = true;
    }
  }, [user]);

  // Terms of Use compliance - backfill users who have not accepted terms
  useEffect(() => {
    const redirectToTerms = () => {
      const allowedRoutes = [
        ROUTES.TERMS,
        ROUTES.TERMS_OF_USE,
        ROUTES.PRIVACY,
        ROUTES.MEMBERSHIP_FAILED + "/",
        ROUTES.LOGOUT + "/",
      ];

      // redirect to /terms UNLESS: user is false, user has accepted terms, or user is on an allowed route
      if (
        !user ||
        user.accepted_terms ||
        allowedRoutes.includes(router.asPath)
      ) {
        return;
      }

      router.push(ROUTES.TERMS);
    };

    redirectToTerms();

    router.events.on("routeChangeComplete", redirectToTerms);

    return () => {
      router.events.off("routeChangeComplete", redirectToTerms);
    };
  }, [router, user]);

  // Attempt to login at the start of the session, set user data
  const getUserData = async () => {
    try {
      const response = await api({}).post("/api/auth/loginWithToken");

      const { token, user, chatbot_token } = response?.data;

      /**
       *
       * By default a user is not authorized.
       *
       * If user has an active subscription or route is public,
       * user is authorized.
       *
       * If user does not have an active subscription && route is private,
       * user is not authorized and redirected to checkout.
       *
       * In-app page route authorization is handled in the RouteGuard
       * component. Individual gated content is authorized in the
       * via BFF /authorized endpoint via useBFFContent hook, which 
       * makes use of the /api/gated-bff-content endpoint.
       *
       */

      const hasPaymentIssue = validateStripeStatus(user?.subscription);

      if (hasPaymentIssue) {
        router.replace(ROUTES.MEMBERSHIP_FAILED);
      }
      setUserData({ user, token, chatbot_token });
      setAuthVerified(true);
    } catch (error) {
      // If user is not logged in, redirect private routes to login.
      console.error("getUserData", error);
      setUser(false);
      setAuthVerified(true);
    }
  };

  useEffect(() => {
    if (!growthbook || user === null) return; // no attempt to login
    window?.analytics?.ready(function () {
      growthbook.setAttributes({
        ...growthbook.getAttributes(),
        anonymousID: window.analytics.user().anonymousId(),
      });
    });
  }, [growthbook, user]);

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setUserData = ({ user, token, chatbot_token }) => {
    setUser(user);
    setToken(token);
    setChatbotToken(chatbot_token);

    Cookies.set(GI_JWT_TOKEN_COOKIE, token, {
      secure: process.env.VERCEL_ENV !== "development",
      sameSite: "Lax",
    });

    Sentry.setUser({ email: user.email });
    // Set user attributes for targeting (from cookie, auth system, etc.)
    growthbook.setAttributes({
      ...growthbook.getAttributes(),
      id: user.id,
      email: user.email,
      userAgent: navigator.userAgent,
      member: user.subscription_active,
      admin:
        user.email?.includes("@goodinside.com") ||
        user.email?.includes("@course.studio"),
      ...(!!user.topic && { topic: user.topic }),
      createdAt: user?.subscription?.created_at
        ? new Date(user?.subscription?.created_at).getTime() / 1000
        : null,
    });
  };

  const login = async ({
    email,
    password,
    purchaseUrl,
    postId,
    isRegistering,
    nextUrl,
  }) => {
    await api({ method: "post" }).request({
      url: "/api/login",
      data: {
        email: email,
        password: password,
      },
    });
    handleLoginRedirect(purchaseUrl, postId, isRegistering, nextUrl);
  };

  const register = async ({
    email,
    password,
    password_confirmation,
    first_name,
    last_name,
    purchaseUrl,
    postId,
    accepted_terms,
    nextUrl,
  }) => {
    await api({ method: "post" }).request({
      url: "/api/register",
      data: {
        email,
        password,
        password_confirmation,
        first_name,
        last_name,
        purchaseUrl,
        accepted_terms,
      },
    });
    login({
      email,
      password,
      purchaseUrl,
      postId,
      isRegistering: true,
      nextUrl,
    });
  };

  const handleLoginRedirect = (purchaseUrl, postId, isRegistering, nextUrl) => {
    const defaultRedirect = `${USER_AUTH_URL}/login/callback/?referrerUrl=${encodeURIComponent(
      nextUrl ? nextUrl : router.asPath
    )}`;
    if (purchaseUrl) {
      router.push(
        `${defaultRedirect}&purchaseUrl=${purchaseUrl}&postId=${postId}`
      );
    } else if (isRegistering) {
      router.push(`${defaultRedirect}&isRegistering=true`);
    } else {
      router.push(defaultRedirect);
    }
  };

  // Return the user object and auth methods
  return {
    token,
    chatbotToken,
    login,
    register,
    getUserData,
  };
}

// Provider component that wraps your app and makes auth object
const AuthProvider = ({ children }) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}> {children} </AuthContext.Provider>;
};

// Hook for child components to get the auth object
const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
