import cn from "classnames";
import dynamic from "next/dynamic";
import React from "react";

// Logos
const Apple = dynamic(() => import("./logos/Apple"));
const Facebook = dynamic(() => import("./logos/Facebook"));
const GiLogoSmall = dynamic(() => import("./logos/GiLogoSmall"));
const GiLogo = dynamic(() => import("./logos/GiLogo"));
const GiLogoLarge = dynamic(() => import("./logos/GiLogoLarge"));
const GiWordmark = dynamic(() => import("./logos/GiWordmark"));
const GiWordmarkLarge = dynamic(() => import("./logos/GiWordmarkLarge"));
const GoogleWordmark = dynamic(() => import("./logos/GoogleWordmark"));
const Instagram = dynamic(() => import("./logos/Instagram"));
const LinkedIn = dynamic(() => import("./logos/Linkedin"));
const Pinterest = dynamic(() => import("./logos/Pinterest"));
const PodcastApple = dynamic(() => import("./logos/PodcastApple"));
const PodcastSpotify = dynamic(() => import("./logos/PodcastSpotify"));
const Stripe = dynamic(() => import("./logos/Stripe"));
const Tiktok = dynamic(() => import("./logos/Tiktok"));
const Twitter = dynamic(() => import("./logos/Twitter"));
const Youtube = dynamic(() => import("./logos/Youtube"));

// Glyphs
const ArrowLeft = dynamic(() => import("./glyphs/ArrowLeft"));
const ArrowRight = dynamic(() => import("./glyphs/ArrowRight"));
const ArrowRightLarge = dynamic(() => import("./glyphs/ArrowRightLarge"));
const Article = dynamic(() => import("./glyphs/Article"));
const Audio = dynamic(() => import("./glyphs/Audio"));
const CaretDown = dynamic(() => import("./glyphs/CaretDown"));
const CaretLeft = dynamic(() => import("./glyphs/CaretLeft"));
const CaretUp = dynamic(() => import("./glyphs/CaretUp"));
const Close = dynamic(() => import("./glyphs/Close"));
const Collapse = dynamic(() => import("./glyphs/Collapse"));
const Complete = dynamic(() => import("./glyphs/Complete"));
const ContentComplete = dynamic(() => import("./glyphs/ContentComplete"));
const Document = dynamic(() => import("./glyphs/Document"));
const Download = dynamic(() => import("./glyphs/Download"));
const DrawerCaret = dynamic(() => import("./glyphs/DrawerCaret"));
const Enlarge = dynamic(() => import("./glyphs/Enlarge"));
const Expand = dynamic(() => import("./glyphs/Expand"));
const ExternalLink = dynamic(() => import("./glyphs/ExternalLink"));
const Filter = dynamic(() => import("./glyphs/Filter"));
const Gift = dynamic(() => import("./glyphs/Gift"));
const HearingAccessible = dynamic(() => import("./glyphs/HearingAccessible"));
const LightBulb = dynamic(() => import("./glyphs/LightBulb"));
const Link = dynamic(() => import("./glyphs/Link"));
const LiveWorkshop = dynamic(() => import("./glyphs/LiveWorkshop"));
const Lock = dynamic(() => import("./glyphs/Lock"));
const Menu = dynamic(() => import("./glyphs/Menu"));
const PlayArrow = dynamic(() => import("./glyphs/PlayArrow"));
const Podcast = dynamic(() => import("./glyphs/Podcast"));
const Quote = dynamic(() => import("./glyphs/Quote"));
const Save = dynamic(() => import("./glyphs/Save"));
const Saved = dynamic(() => import("./glyphs/Saved"));
const Script = dynamic(() => import("./glyphs/Script"));
const Search = dynamic(() => import("./glyphs/Search"));
const Share = dynamic(() => import("./glyphs/Share"));
const ShareIOS = dynamic(() => import("./glyphs/ShareIOS"));
const ShareAndroid = dynamic(() => import("./glyphs/ShareAndroid"));
const SmallArticle = dynamic(() => import("./glyphs/SmallArticle"));
const SmallDownload = dynamic(() => import("./glyphs/SmallDownload"));
const SmallDocument = dynamic(() => import("./glyphs/SmallDocument"));
const SmallLive = dynamic(() => import("./glyphs/SmallLive"));
const SmallPodcast = dynamic(() => import("./glyphs/SmallPodcast"));
const SmallScript = dynamic(() => import("./glyphs/SmallScript"));
const SmallVideo = dynamic(() => import("./glyphs/SmallVideo"));
const SmallWorkshop = dynamic(() => import("./glyphs/SmallWorkshop"));
const StarBullet = dynamic(() => import("./glyphs/StarBullet"));
const Stars = dynamic(() => import("./glyphs/Stars"));
const Tick = dynamic(() => import("./glyphs/Tick"));
const TickCursive = dynamic(() => import("./glyphs/TickCursive"));
const TickCursiveLg = dynamic(() => import("./glyphs/TickCursiveLg"));
const TickRounded = dynamic(() => import("./glyphs/TickRounded"));
const Time = dynamic(() => import("./glyphs/Time"));
const Verified = dynamic(() => import("./glyphs/Verified"));
const Video = dynamic(() => import("./glyphs/Video"));
const Webview = dynamic(() => import("./glyphs/WebviewSettings"));
const Workshop = dynamic(() => import("./glyphs/Workshop"));

const Icon = ({ name, color = "", className = "", ...props }) => {
  // Icons support color via `currentColor` property
  const colors = {
    black: "text-black",
    white: "text-white",
    yellow: "text-yellow",
  };

  const glyphs = {
    audio: <Audio />,
    arrowLeft: <ArrowLeft />,
    arrowRight: <ArrowRight />,
    arrowRightLarge: <ArrowRightLarge />,
    caretDown: <CaretDown />,
    caretLeft: <CaretLeft />,
    caretUp: <CaretUp />,
    close: <Close />,
    collapse: <Collapse />,
    complete: <Complete />,
    contentComplete: <ContentComplete />,
    document: <Document />,
    download: <Download />,
    drawerCaret: <DrawerCaret />,
    expand: <Expand />,
    enlarge: <Enlarge />,
    externalLink: <ExternalLink />,
    fallback: <Close />,
    filter: <Filter />,
    gift: <Gift />,
    hearingAccessible: <HearingAccessible />,
    lightBulb: <LightBulb />,
    link: <Link />,
    lock: <Lock />,
    menu: <Menu />,
    playArrow: <PlayArrow />,
    quote: <Quote />,
    save: <Save />,
    saved: <Saved />,
    search: <Search />,
    share: <Share />,
    shareandroid: <ShareAndroid />,
    shareios: <ShareIOS />,
    smallDownload: <SmallDownload />,
    starBullet: <StarBullet />,
    stars: <Stars />,
    tick: <Tick />,
    tickCursive: <TickCursive />,
    tickCursiveLg: <TickCursiveLg />,
    tickRounded: <TickRounded />,
    time: <Time />,
    verified: <Verified />,
    webview: <Webview />,
  };

  const content = {
    article: <Article />,
    live: <LiveWorkshop />,
    podcast: <Podcast />,
    script: <Script />,
    smallArticle: <SmallArticle />,
    smallDocument: <SmallDocument />,
    smallLive: <SmallLive />,
    smallPodcast: <SmallPodcast />,
    smallScript: <SmallScript />,
    smallVideo: <SmallVideo />,
    smallWorkshop: <SmallWorkshop />,
    video: <Video />,
    workshop: <Workshop />,
  };

  const logos = {
    apple: <Apple />,
    facebook: <Facebook />,
    giLogoSmall: <GiLogoSmall />,
    giLogo: <GiLogo />,
    giLogoLarge: <GiLogoLarge />,
    giWordmark: <GiWordmark />,
    giWordmarkLarge: <GiWordmarkLarge />,
    googleWordmark: <GoogleWordmark />,
    instagram: <Instagram />,
    linkedin: <LinkedIn />,
    pinterest: <Pinterest />,
    podcastsApple: <PodcastApple />,
    podcastsSpotify: <PodcastSpotify />,
    stripe: <Stripe />,
    tiktok: <Tiktok />,
    twitter: <Twitter />,
    youtube: <Youtube />,
  };

  // Combine
  const icons = Object.assign(glyphs, content, logos);

  // Ensure glyph is found
  const glyphExists = icons.hasOwnProperty(name);

  // Handle fallback
  const render = glyphExists ? icons[name] : icons["fallback"];

  return (
    <div
      className={cn(
        "fill-current",
        { "bg-red": !glyphExists },
        colors[color],
        className
      )}
      {...props}
    >
      {render}
    </div>
  );
};

export default Icon;
