import { USER_ISSUES_TYPES as ISSUE_TYPES } from "@/hooks/useIssue";
import api from "@/lib/api";
import { identify } from "@/lib/helpers/analytics";
import validateSubscriptionExpiry from "@/lib/helpers/validateSubscriptionExpiry";
import differenceInDays from "date-fns/differenceInDays";
import { createContext, useContext, useEffect, useState } from "react";

const UserContext = createContext();

function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [bookmarks, setBookmarks] = useState([]);
  const [recents, setRecents] = useState([]);
  const [workshops, setWorkshops] = useState([]);
  const [issues, setIssue] = useState(new Set());
  const [purchases, setPurchases] = useState([]);
  const [userIsNew, setUserIsNew] = useState(false);
  const [userSubscriptionDate, setUserSubscriptionDate] = useState(null);
  const [topic, setTopic] = useState(null);
  const [userChildren, setUserChildren] = useState([]);
  const [authVerified, setAuthVerified] = useState(false);

  useEffect(() => {
    // User is not logged in or logged in user is not a member
    // user == null means we don't know so don't do anything
    // user === false means they are logged out
    // or user is logged in AND user does not have a subscription
    if (!user) return;
    /**
     * The following properties are returned from JSONB fields from our database.
     * We need to parse them or set default of empty array, stringified.
     */
    setBookmarks(JSON.parse(user.bookmarked || "[]"));
    setRecents(JSON.parse(user.recently_viewed || "[]"));
    setWorkshops(JSON.parse(user.workshops || "[]"));
    setPurchases(JSON.parse(user.purchases || "[]"));
    setTopic(user?.topic || null);
    setUserChildren(user.children || "[]");

    // remove nextUrl, used for login redirect
    localStorage.removeItem("nextUrl");

    // if any traits were set in localstorage during acquisition,
    // add them as traits, then clear them
    const acquisitionIdentifyTraits = JSON.parse(
      localStorage.getItem("acquisitionIdentifyTraits")
    );

    let newTraits = {};
    if (acquisitionIdentifyTraits) {
      for (const key in acquisitionIdentifyTraits) {
        if (
          Array.isArray(acquisitionIdentifyTraits[key]) &&
          acquisitionIdentifyTraits[key].length > 0
        ) {
          newTraits[key] = {};
          acquisitionIdentifyTraits[key].forEach((item) => {
            newTraits[key][item] = true;
          });
        } else {
          newTraits[key] = acquisitionIdentifyTraits[key];
        }
      }
      localStorage.removeItem("acquisitionIdentifyTraits");
    }
    if (Object.keys(newTraits).length > 0) {
      identify(user.id, { email: user.email, ...newTraits });
    } else {
      identify(user.id, { email: user.email });
    }

    const isSubscriptionExpiring = (user) => {
      if (!user && !!user?.subscription) return false;
      const subscriptionActive = user.subscription_active;

      return (
        subscriptionActive &&
        validateSubscriptionExpiry(user?.subscription?.ends_at)
      );
    };

    // If subscription is going to expire set issue on user
    // in order to display the issues Banner
    if (isSubscriptionExpiring(user)) {
      setIssue(
        (issues) => new Set([...issues, ISSUE_TYPES.SUBSCRIPTION_EXPIRING])
      );
    }

    if (differenceInDays(new Date(), new Date(user.created_at)) <= 14) {
      setUserIsNew(true);
    }

    if (user.subscription?.created_at) {
      setUserSubscriptionDate(
        new Date(
          user.subscription.created_at.substring(0, 10).replaceAll("-", "/")
        ).getTime()
      );
    } else if (user.subscription_active && user.grace) {
      // If the user is in grace period, set the subscription date to today
      setUserSubscriptionDate(new Date().getTime());
    }
  }, [user]);

  const updateAcceptedTerms = (acceptedTerms) => {
    setUser((prevUser) => ({
      ...prevUser,
      accepted_terms: acceptedTerms,
    }));
  };

  /**
   * Function to update the bookmarked posts server side
   *
   * @param {postIds} array of ID strings
   * @param {remove} bool whether we are removing this post
   */
  const updateBookmarks = async ({ postIds = [], remove = false }) => {
    if (!postIds.length) return;

    try {
      const res = await api({ method: remove ? "delete" : "post" }).request({
        url: "api/user/bookmarks",
        data: {
          ids: postIds,
        },
      });
      setBookmarks(res?.data || []);
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Function to generate a shareable content url
   *
   * @param {contentId} number content ID
   * @param {contentType} string content type
   * @param {url} string content url
   */
  const generateContentShareURL = async ({ contentId, contentType, url }) => {
    const res = await api({ method: "POST" }).request({
      url: "api/content/generate-url",
      data: {
        content_id: contentId,
        content_type: contentType,
        content_url: url,
      },
    });

    return res?.data?.url || null;
  };

  /**
   * Function to update the recently viewed post server side
   *
   * @param {posts} array of ID strings
   * @param {remove} bool whether we are removing this post
   *
   */
  const updateRecentlyViewed = async ({ posts = [], remove = false }) => {
    if (!posts.length || !user?.id) return;

    const res = await api({ method: remove ? "delete" : "post" }).request({
      url: "api/user/recents",
      data: {
        posts,
      },
    });

    setRecents(res?.data || []);
  };

  /**
   * Function to update progress of workshop
   *
   * @param {id} string
   */
  const trackWorkshopProgress = async ({ id, remove = false }) => {
    if (!id) return;

    // Only track progress for members or if user has purchased the workshop
    if (!user?.subscription_active && !user?.purchases?.includes(id)) {
      return;
    }

    const res = await api({ method: remove ? "delete" : "post" }).request({
      url: "api/user/workshops",
      data: {
        posts: [id],
      },
    });
    setWorkshops(res?.data || []);
  };

  const value = {
    setUser,
    user,
    bookmarks,
    recents,
    workshops,
    topic,
    userChildren,
    issues,
    updateBookmarks,
    generateContentShareURL,
    updateRecentlyViewed,
    trackWorkshopProgress,
    purchases,
    userIsNew,
    userSubscriptionDate,
    updateAcceptedTerms,
    setAuthVerified,
    authVerified,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUser };
