export const BASE_URL = process.env.NEXT_PUBLIC_SITE_URL;

export const USER_AUTH_URL = process.env.NEXT_PUBLIC_USER_AUTH_URL;
export const BFF_URL = process.env.NEXT_PUBLIC_BFF_URL;
export const USER_PROGRESS_URL = process.env.NEXT_PUBLIC_LOCAL_API_URL
  ? process.env.NEXT_PUBLIC_LOCAL_API_URL
  : process.env.NEXT_PUBLIC_VERCEL_URL;

export const AUTH_CMS_URL = process.env.AUTH_CMS_URL;
export const AUTH_CMS_USERNAME = process.env.AUTH_CMS_USERNAME;
export const AUTH_CMS_PASSWORD = process.env.AUTH_CMS_PASSWORD;
export const PROXIED_CMS_URL = process.env.PROXIED_CMS_URL;
export const FRONTEND_DOMAIN = process.env.NEXT_PUBLIC_DOMAIN;

export const LOGIN_URL = `${USER_AUTH_URL}/login`;
export const PROFILE_URL = `${USER_AUTH_URL}/user/profile`;
export const BILLING_PORTAL_URL = `${USER_AUTH_URL}/billing-portal`;

export const GOOGLE_AUTH_URL = `${USER_AUTH_URL}/auth/google/redirect`;

export const USER_PROFILE_API_URL = `/api/user/userprofile`;
export const USER_CHILDREN_API_URL = `/api/user/child`;
export const USER_ACCEPT_TERMS_API_URL = `/api/user/acceptTerms`;
export const USER_ID_API_URL = `/api/user/id`;
export const USER_PAYMENTINTENT_API_URL = `/api/user/paymentIntent`;

export const COMMUNITY_URL = process.env.NEXT_PUBLIC_COMMUNITY_URL;
export const COMMUNITY_PROFILE_URL = `${COMMUNITY_URL}/settings/profile`;
export const COMMUNITY_API_URL = "https://app.circle.so/api/v1";
export const COMMUNITY_ID = process.env.COMMUNITY_ID;
export const COMMUNITY_API_KEY = process.env.CIRCLE_API_KEY;

export const BUILDER_API_KEY = process.env.NEXT_PUBLIC_BUILDER_API_KEY;

export const VIDEO_HOST_ACCOUNT_ID = process.env.NEXT_PUBLIC_VIDEO_ACCOUNT_ID;
export const VIDEO_PLAYER_ID = process.env.NEXT_PUBLIC_VIDEO_PLAYER_ID;
export const VIDEO_PLAYLIST_PLAYER_ID =
  process.env.NEXT_PUBLIC_VIDEO_PLAYLIST_PLAYER_ID;

export const GI_CIRCLE_COOKIE = "gi_circle_cookie";
export const GI_JWT_TOKEN_COOKIE = "gi_jwt_cookie";
export const RETURNING_SUBSCRIBER_KEY = "gi-returning-user";
export const NEW_LIBRARY_USER = "gi-library-first";
export const DISMISSED_BANNERS_KEY = "gi-dismissed-banners";

export const SMS_ACCEPTED_COUNTRIES = ["US", "CA", "UK", "GB"];

export const PLAYER_OPTIONS = {
  aspectRatio: "16:9",
  fluid: true,
};

export const MENU_KEY = "good_inside_menus";
export const SOCIAL_LINKS_KEY = "good_inside_social_menus";
export const PAYWALL_KEY = "good_inside_pay_wall";
export const GATED_SKELETON_SCHEMA_KEY = "good_inside_gated_skeleton_schema";

export const FILTER_ID_IDENTIFIER = {
  topics: "topicId",
  lengths: "lengthId",
  types: "name",
};

// This value is dependent on the container class in styles\index.css
export const CONTAINER_WIDTH = 1248;

export const SM_BREAKPOINT = 625;
export const MD_BREAKPOINT = 768;
export const LG_BREAKPOINT = 1024;
export const XXL_BREAKPOINT = 1535;

export const BUILDER_MD_BREAKPOINT = 640;
export const BUILDER_LG_BREAKPOINT = 992;

// Header, Alert Banner, and Status Banner
export const HEADER_HEIGHT = 112;
export const MOBILE_BANNER_HEIGHT = 48;
export const DESKTOP_BANNER_HEIGHT = 40;

// Content
export const CTA_TEXT = "GET STARTED";
export const SECTION_CTA_TEXT = "JOIN GOOD INSIDE";
export const USER_CTA_TEXT = "LIBRARY";

// Feature flags
// 

export const COMPLETE_PERCENT = 95;

// Moments

export const MOMENTS_DIV_ID = "moments";

export const MINIMUM_TIME_TO_COMPLETE_TEXT_CONTENT_MS = 10000;

// Segment
export const SEGMENT_WRITE_KEY = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY;
export const SEGMENT_ONBOARDING_VERSION = "3";

// One Trust
export const ONE_TRUST_KEY = process.env.NEXT_PUBLIC_ONE_TRUST_KEY;

export const FORCE_SHOW_BUILDER =
  process.env.NEXT_PUBLIC_VERCEL_ENV === "development";

export const DEWEY_KEY = process.env.NEXT_PUBLIC_DEWEY_KEY;
export const DEWEY_MOBILE_KEY = process.env.NEXT_PUBLIC_DEWEY_MOBILE_KEY;

/** Use this when you do not want pages to pre-render */
export const SKIP_BUILD_STATIC_GENERATION =
  process.env.SKIP_BUILD_STATIC_GENERATION &&
  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF &&
  !["main", "uat"].includes(process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF);

export const BRANCH_APP_LINK = process.env.NEXT_PUBLIC_BRANCH_APP_LINK;
export const BRANCH_APP_QR = process.env.NEXT_PUBLIC_BRANCH_APP_QR;
export const ENABLE_MAGIC_LINKING = process.env.NEXT_PUBLIC_ENABLE_MAGIC_LINKING;
