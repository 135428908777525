import HeaderIcon from "./Header/HeaderIcon";
import AlertBanner from "@/components/Banner/AlertBanner";
import StatusBanner from "@/components/Banner/StatusBanner";
import Button from "@/components/Button";
import Container from "@/components/Containers/Container";
import Menu from "@/components/Menu";
import { UserMenu } from "@/components/Menu/UserMenu";
import OnboardingButton from "@/components/OnboardingButton";
import LoginButton from "@/components/Registration/LoginButton";
import SearchIcon from "@/components/SearchIcon";
import SkeletonMenuItem from "@/components/Skeleton/MenuItem";
import { useMobileMenu } from "@/context/MobileMenuContext";
import { useUI } from "@/context/UIContext";
import { useUser } from "@/context/UserContext";
import useMediaQuery, { desktopQuery } from "@/hooks/useMediaQuery";
import {
  HEADER_HEIGHT,
  MOBILE_BANNER_HEIGHT,
  DESKTOP_BANNER_HEIGHT,
} from "@/lib/constants";
import { CTA_TEXT } from "@/lib/constants";
import debounce from "@/util/debounce";
import cn from "classnames";
import { forwardRef, useEffect, useCallback, useState } from "react";

const SiteHeader = (
  {
    showHeaderCta = true,
    eventLogo,
    transparent = false,
    headerCta = undefined,
  },
  ref
) => {
  const { user, authVerified } = useUser();
  const {
    displayStatusBanner,
    displayAlertBanner,
    focusHeader,
    setFocusHeader,
    dispatchModal,
    innerWidth,
    minimalHeader,
  } = useUI();
  const { showMobileMenu } = useMobileMenu();
  const { dispatch, setShowMobileMenu } = useMobileMenu();
  const [loaded, setLoaded] = useState(false);
  const isDesktop = useMediaQuery(desktopQuery);
  const [calculatedHeaderHeight, setCalculatedHeaderHeight] =
    useState(HEADER_HEIGHT);

  const closeMobileMenus = () => {
    dispatch({ type: "SHOW_OPEN" });
    setShowMobileMenu(false);
  };

  const handleScroll = useCallback(() => {
    setFocusHeader(window.scrollY > 0);
  }, [setFocusHeader]);

  const enableAccessibility = () => {
    enableUsableNetAssistive();
  };

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    const hasBanner = displayAlertBanner || displayStatusBanner;
    const bannerMultiplier = isDesktop
      ? DESKTOP_BANNER_HEIGHT
      : MOBILE_BANNER_HEIGHT;
    const bannerHeight = (hasBanner ? 1 : 0) * bannerMultiplier;

    setCalculatedHeaderHeight(
      transparent ? bannerHeight : bannerHeight + HEADER_HEIGHT
    );
  }, [displayAlertBanner, displayStatusBanner, isDesktop, transparent]);

  useEffect(() => {
    if (showMobileMenu) {
      dispatchModal({
        type: "SET_OPEN",
        value: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMobileMenu]);

  useEffect(() => {
    // scroll listener only for transparent header
    // this causes re-render in adjacent useEffect functions & should be avoided if not necessary
    if (!transparent) return;
    const eventListenerFunction = debounce(handleScroll, 10);
    window.addEventListener("scroll", eventListenerFunction);
    return () => window.removeEventListener("scroll", eventListenerFunction);
  }, [handleScroll, transparent]);

  return (
    <>
      <div
        className="w-full transition-all duration-500 accessibility-mode-hidden"
        style={{ height: `${calculatedHeaderHeight}px` }}
      ></div>
      <header
        className={cn(
          "fixed top-0 z-50 w-screen transition-colors duration-150",
          {
            "bg-white border-b-1 border-silver text-black":
              !transparent || focusHeader,
            "bg-none text-white":
              transparent && !focusHeader && !showMobileMenu,
          }
        )}
        role="banner"
        ref={ref}
      >
        <div className="w-full py-[0.625rem] bg-medium-gray">
          <Container className="flex">
            {loaded ? (
              <Button
                variant="text"
                className="text-xs tracking-[0.047rem] hover:!text-[#156747] leading-[1.375rem] UsableNetAssistive"
                onClick={enableAccessibility}
              >
                Enable Accessibility
              </Button>
            ) : (
              <Button
                variant="text"
                disabled
                className="text-xs tracking-[0.047rem] leading-[1.375rem] opacity-40 UsableNetAssistive"
                title="Button loading"
              >
                Enable Accessibility
              </Button>
            )}
          </Container>
        </div>

        {displayStatusBanner && <StatusBanner />}
        {!displayStatusBanner && <AlertBanner />}
        <Container className="relative">
          <div className="flex items-center h-full">
            <div
              className={cn("flex gap-5 mr-auto lg:flex-row-reverse", {
                "lg:w-full": !minimalHeader,
              })}
            >
              {!minimalHeader && (
                <Menu
                  workshopNav={transparent}
                  buttonDataId={headerCta?.buttonDataId}
                />
              )}
              {authVerified && <SearchIcon btnHandler={closeMobileMenus} />}
            </div>
            <div
              className={cn(
                "flex h-17 flex-shrink-0 items-center",
                minimalHeader ? "ml-auto" : "ml-2"
              )}
            >
              {!authVerified && !minimalHeader && innerWidth >= 1024 ? (
                <>
                  <SkeletonMenuItem />
                  <SkeletonMenuItem />
                  <SkeletonMenuItem />
                </>
              ) : (
                <>
                  {user ? (
                    <div className="flex items-center h-full gap-6">
                      {!user.subscription_active &&
                        !minimalHeader &&
                        showHeaderCta && (
                          <div className="lg-max:hidden">
                            <OnboardingButton
                              buttonDataId={headerCta?.buttonDataId}
                            >
                              {CTA_TEXT}
                            </OnboardingButton>
                          </div>
                        )}
                      <UserMenu />
                    </div>
                  ) : (
                    <div className="flex items-center h-full gap-8">
                      <LoginButton />
                      <div className="lg-max:hidden">
                        <OnboardingButton
                          buttonDataId={headerCta?.buttonDataId}
                        >
                          {CTA_TEXT}
                        </OnboardingButton>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <HeaderIcon eventLogo={eventLogo} />
        </Container>

        {process.env.NEXT_PUBLIC_BETA === "true" && (
          <small className="absolute left-1/2 bottom-[-0.875em] z-30 flex h-[1.9em] -translate-x-1/2 items-center rounded-full border-2 border-white bg-dark-green px-3 text-xs font-bold uppercase">
            Beta
          </small>
        )}
      </header>
    </>
  );
};

export default forwardRef(SiteHeader);
